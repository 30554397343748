export type SnowplowContext =
  | CustomContext
  | CustomerContext
  | ProductContext
  | UserContext;

export enum SnowplowSchemaType {
  CustomSchema = 'iglu:com.visma-tech-hubs/context_custom/jsonschema/1-0-0',
  CustomerSchema = 'iglu:com.visma-tech-hubs/context_customer/jsonschema/1-0-0',
  ProductSchema = 'iglu:com.visma-tech-hubs/context_product/jsonschema/1-0-0',
  UserSchema = 'iglu:com.visma-tech-hubs/context_user/jsonschema/1-0-0',
}

export interface CustomContext extends Record<string, unknown> {
  custom_name?: string;
  customfield_custom1?: string;
  customfield_custom2?: string;
  customfield_custom3?: string;
}

export interface CustomerContext extends Record<string, unknown> {
  business_unit: string;
  company_name?: string;
  countryiso?: string; // string with two chars: XX
  customer_name: string;
  customfield_customer1?: string;
  customfield_customer2?: string;
  customfield_customer3?: string;
  organization_no?: string;
}

export interface ProductContext extends Record<string, unknown> {
  customfield_product1?: string;
  customfield_product2?: string;
  customfield_product3?: string;
  module_name?: string;
  module_no?: string;
  product_name: string;
  r12?: string;
}

export interface UserContext extends Record<string, unknown> {
  customfield_user1?: string;
  customfield_user2?: string;
  customfield_user3?: string;
  registration_date?: string; // yyyy-mm-dd
  user_type: string;
}
