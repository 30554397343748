import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppConfig } from '../models/app-config.model';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(private http: HttpClient) {}

  public load() {
    return this.http.get<AppConfig>('assets/config/config.json').pipe(
      tap(config => {
        environment.snowplowUrl = config.snowplowUrl;
        environment.ratesApiHost = config.ratesApiHost;
        environment.fetchVatRatesFromApi = config.fetchVatRatesFromApi;
        environment.fetchInterestRatesFromApi = config.fetchInterestRatesFromApi;
        environment.fetchMealRatesFromApi = config.fetchMealRatesFromApi;
      }),
      catchError(error => {
        console.error('Could not load file config.json');
        return error;
      })
    );
  }
}
