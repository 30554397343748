export const environment = {
  production: true,
  logging: false,
  snowplowUrl: 'https://snowplow-test.visma.com',
  meal_calculator_api_url: '/api/mealcalculator/rates',
  ukessatser_valuta_api_url: '/api/valutaukessatser',
  valuta_api_url: '/api/valuta',
  ratesApiHost: 'https://api2.sticos.no/oppslag/calculatorrates',
  fetchVatRatesFromApi: 'disabled',
  fetchInterestRatesFromApi: 'disabled',
  fetchMealRatesFromApi: 'disabled',
  fetchGrunnbelopFromApi: 'disabled',
};
