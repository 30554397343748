import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MaintenanceComponent } from './maintenance/maintenance.component';

/* eslint-disable max-len */
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    pathMatch: 'full',
  },

  {
    path: 'agp',
    loadChildren: () =>
      import('./agp-calculator/agp-calculator.module').then(m => m.AgpCalculatorModule),
  },
  {
    path: 'beregning-av-sykepenger',
    loadChildren: () =>
      import('./sickness-benefit-agp/sickness-benefit-agp.module').then(
        m => m.SicknessBenefitAGPModule
      ),
  },
  { path: 'maltidstrekkalkulator-2018', redirectTo: '/maltidstrekkalkulator' },
  { path: 'maltidstrekkalkulator-2019', redirectTo: '/maltidstrekkalkulator' },
  { path: 'maltidstrekkalkulator-2020', redirectTo: '/maltidstrekkalkulator' },
  {
    path: 'maltidstrekkalkulator',
    loadChildren: () =>
      import('./meal-calculator/meal-calculator.module').then(m => m.MealCalculatorModule),
  },
  {
    path: 'valutakalkulator',
    loadChildren: () =>
      import('./currency-calculator/currency-calculator.module').then(
        m => m.CurrencyCalculatorModule
      ),
  },
  {
    path: 'dager-mellom-datoer',
    loadChildren: () =>
      import('./days-between-dates/days-between-dates.module').then(m => m.DaysBetweenDatesModule),
  },

  { path: 'mva-kalkulator', redirectTo: '/mva' },
  {
    path: 'mva',
    loadChildren: () =>
      import('./vat-calculator/vat-calculator.module').then(m => m.VatCalculatorModule),
  },

  { path: 'rente-kalkulator', redirectTo: '/rente' },
  {
    path: 'rente',
    loadChildren: () =>
      import('./interest-calculator/interest-calculator.module').then(
        m => m.InterestCalculatorModule
      ),
  },

  { path: 'forsinkelsesrente-kalkulator', redirectTo: '/forsinkelsesrente' },
  {
    path: 'forsinkelsesrente',
    loadChildren: () =>
      import('./late-payment-interest/late-payment-interest.module').then(
        m => m.LatePaymentInterestModule
      ),
  },

  {
    path: 'summerings-og-dokumentasjonsstrimmel',
    loadChildren: () =>
      import('./summary-and-documentation-strip/summary-and-documentation-strip.module').then(
        m => m.SummaryAndDocumentationStripModule
      ),
  },

  {
    path: 'konsumprisindeks',
    loadChildren: () =>
      import('./consumer-price-index/consumer-price-index.module').then(
        m => m.ConsumerPriceIndexModule
      ),
  },

  {
    path: 'naverdi',
    loadChildren: () =>
      import('./net-present-value/net-present-value.module').then(m => m.NPVModule),
  },

  {
    path: 'lanekalkulator',
    loadChildren: () =>
      import('./loan-calculator/loan-calculator.module').then(m => m.LoanCalculatorModule),
  },

  {
    path: 'utbytte-vs-lonn',
    loadChildren: () =>
      import('./dividend-vs-salary/dividend-vs-salary.module').then(m => m.DividendVsSalaryModule),
  },

  {
    path: 'firmabil',
    loadChildren: () => import('./company-car/company-car.module').then(m => m.CompanyCarModule),
  },

  { path: '**', redirectTo: '/' },

  { path: 'vedlikehold', component: MaintenanceComponent },
];
/* eslint-enable max-len */

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
