import { Injectable } from '@angular/core';
import {
  BrowserTracker,
  enableActivityTracking,
  newTracker,
  SelfDescribingJson,
  setUserId,
  trackPageView,
} from '@snowplow/browser-tracker';
import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing';
import { FormTrackingPlugin } from '@snowplow/browser-plugin-form-tracking';
import { NavigationEnd, Router } from '@angular/router';
import {
  CustomerContext,
  ProductContext,
  SnowplowContext,
  SnowplowSchemaType,
  UserContext,
} from '../models/snowplow.models';
import { environment } from 'src/environments/environment';

@Injectable()
export class SnowplowService {
  private contexts: SelfDescribingJson<SnowplowContext>[] = [];
  private tracker: BrowserTracker;

  constructor(private router: Router) {}

  public startTracking(): void {
    // Added this so that we can manually set when this should be started.
    // For those without config files, that uses environment.ts files, they can modify
    // all of this so that they use in the app module APP_INITIALIZER and useClass: Snowplow
    // then move this.startTracker() into the constructor and this should run with only modifying
    // app.module.ts . That is in case someone want to copy this.
    // Of course even better would be to have a way to build this without hardcoding to a product

    this.initTracker();
    this.initUserAndCustomerContext();
    this.initProductContext();
    this.startTrackingRoutes();
  }

  private initTracker(): void {
    if (this.tracker != null) {
      return;
    }
    this.tracker = newTracker('oppslag', environment.snowplowUrl, {
      appId: 'sticos',
      cookieSameSite: 'Lax',

      cookieSecure: true,
      plugins: [PerformanceTimingPlugin(), FormTrackingPlugin()],
    });
    enableActivityTracking(
      {
        heartbeatDelay: 10,
        minimumVisitLength: 10,
      },
      ['oppslag']
    );
  }

  private initUserAndCustomerContext(): void {
    this.createUserContext();
    this.createCustomerContext();
    setUserId('');
  }
  private createUserContext(): void {
    const userContext: Record<keyof UserContext, string> = {
      user_type: 'User',
      customfield_user1: '',
      customfield_user2: null,
      customfield_user3: null,
      registration_date: null,
    };
    this.contexts.push({
      data: userContext,
      schema: SnowplowSchemaType.UserSchema,
    });
  }

  private createCustomerContext(): void {
    const customerContext: Record<keyof CustomerContext, string> = {
      business_unit: 'Sticos AS',
      company_name: '',
      customer_name: '',
      countryiso: 'NO',
      organisation_no: '',
      customfield_customer1: '',
      customfield_customer2: null,
      customfield_customer3: null,
    };

    this.contexts.push({
      schema: SnowplowSchemaType.CustomerSchema,
      data: customerContext,
    });
  }

  private initProductContext(): void {
    const productContext: Record<keyof ProductContext, string> = {
      module_name: 'Kalkulator',
      product_name: 'Oppslag',
      r12: '485',
      customfield_product1: null,
      customfield_product2: null,
      customfield_product3: null,
    };
    this.contexts.push({
      schema: SnowplowSchemaType.ProductSchema,
      data: productContext,
    });
  }

  private startTrackingRoutes(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // according to snowplow documentation this needs to be done before track pageview:
        // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/javascript-tracker/javascript-tracker-v3/tracking-events/#activity-tracking-page-pings

        trackPageView({ title: event.url, context: this.contexts });
      }
    });
  }
}
