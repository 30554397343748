import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import '@angular/common/locales/global/nb';
import { APP_INITIALIZER, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppConfigService } from './core/services/app-config-service';
import { SnowplowService } from './core/services/snowplow.service';
import './localization';
import { MaintenanceComponent } from './maintenance/maintenance.component';

// eslint-disable-next-line @typescript-eslint/ban-types
export function initApiConfiguration(appConfig: AppConfigService): Function {
  return () => {
    return appConfig.load();
  };
}

@NgModule({
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    AppRoutingModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  declarations: [AppComponent, MaintenanceComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'nb' },
    {
      provide: APP_INITIALIZER,
      useFactory: initApiConfiguration,
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    SnowplowService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
