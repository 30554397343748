import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SnowplowService } from './core/services/snowplow.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private snowPlow: SnowplowService) {
    if (environment.production) {
      this.snowPlow.startTracking();
    }
  }
}
