<div class="container-fluid">
    <div class="row justify-content-center mt-5">
        <div class="col-auto">
            <div class="card">
                <div class="card-body text-center">
                    <span class="fa-layers fa-fw fa-10x" style="color: #003b5c;">
                        <i class="fal fa-triangle"></i>
                        <i class="fas fa-tools" data-fa-transform="shrink-10 down-2"></i>
                    </span>
                    <h3 class="card-title my-3">Vedlikehold og oppdatering</h3>
                    <p class="card-text">Kalkulatoren er midlertidig utilgjengelig på grunn av vedlikehold og oppdatering.</p>
                    <p class="card-text">Ved spørsmål, ta kontakt med fagsupport.</p>
                </div>
            </div>
        </div>
    </div>
</div>
