import config from 'devextreme/core/config';
import { locale, loadMessages } from 'devextreme/localization';

// Load localized messages (English included by default)
const messagesNo = {
    nb: {
        'Yes': 'Ja',
        'No': 'Nei',
        'Cancel': 'Avbryt',
        'Clear': 'Tøm',
        'Done': 'Ferdig',
        'Loading': 'Laster...',
        'Select': 'Velg...',
        'Search': 'Søk...',
        'Back': 'Tilbake',
        'OK': 'OK',

        'dxCollectionWidget-noDataText': 'Ingen data å vise',
        'validation-required': 'Påkrevd',
        'validation-required-formatted': '{0} er påkrevd',
        'validation-numeric': 'Verdien må være et tall',
        'validation-numeric-formatted': '{0} må være et tall',
        'validation-range': 'Verdien er utenfor gyldig område',
        'validation-range-formatted': '{0} er utenfor gyldig område',
        'validation-stringLength': 'Lengden på verdien er ikke korrekt',
        'validation-stringLength-formatted': 'Lengden på {0} er ikke korrekt',
        'validation-custom': 'Verdien er ugyldig',
        'validation-custom-formatted': '{0} er ugyldig',
        'validation-compare': 'Verdiene er ikke like',
        'validation-compare-formatted': '{0} er ikke like',
        'validation-pattern': 'Verdien passer ikke mønsteret',
        'validation-pattern-formatted': '{0} passer ikke mønsteret',
        'validation-email': 'E-posten er ugyldig',
        'validation-email-formatted': '{0} er ugyldig',
        'validation-mask': 'Verdien er ugyldig',

        'dxLookup-searchPlaceholder': 'Minimum antall tegn: {0}',
        'dxList-pullingDownText': 'Dra ned for å oppdatere',
        'dxList-pulledDownText': 'Slipp for å oppdatere',
        'dxList-refreshingText': 'Oppdaterer...',
        'dxList-pageLoadingText': 'Laster...',
        'dxList-nextButtonText': 'Mer',
        'dxList-selectAll': 'Velg alle',
        'dxListEditDecorator-delete': 'Slett',
        'dxListEditDecorator-more': 'Mer',
        'dxScrollView-pullingDownText': 'Dra ned for å oppdatere',
        'dxScrollView-pulledDownText': 'Slipp for å oppdatere',
        'dxScrollView-refreshingText': 'Oppdaterer...',
        'dxScrollView-reachBottomText': 'Laster...',
        'dxDateBox-simulatedDataPickerTitleTime': 'Velg tid',
        'dxDateBox-simulatedDataPickerTitleDate': 'Velg dato',
        'dxDateBox-simulatedDataPickerTitleDateTime': 'Velg dato og tid',
        'dxDateBox-validation-datetime': 'Verdien må være en dato eller en tid',
        'dxFileUploader-selectFile': 'Velg fil',
        'dxFileUploader-dropFile': 'eller dra hit',
        'dxFileUploader-bytes': 'Bytes',
        'dxFileUploader-kb': 'kb',
        'dxFileUploader-Mb': 'Mb',
        'dxFileUploader-Gb': 'Gb',
        'dxFileUploader-upload': 'Last opp',
        'dxFileUploader-uploaded': 'Lastet opp',
        'dxFileUploader-readyToUpload': 'Klar til å laste opp',
        'dxFileUploader-uploadFailedMessage': 'Feil ved opplasting',
        'dxRangeSlider-ariaFrom': 'Fra',
        'dxRangeSlider-ariaTill': 'Til',
        'dxSwitch-onText': 'PÅ',
        'dxSwitch-offText': 'AV',
        'dxForm-optionalMark': 'valgfritt',
        'dxForm-requiredMessage': '{0} er et obligatorisk felt',
        'dxNumberBox-invalidValueMessage': 'Verdien må være et tall',

        'dxDataGrid-columnChooserTitle': 'Kolonnevalg',
        'dxDataGrid-columnChooserEmptyText': 'Dra kolonner hit for å skjule dem',
        'dxDataGrid-groupContinuesMessage': 'Fortsetter på neste side',
        'dxDataGrid-groupContinuedMessage': 'Fortsetter fra forrige side',
        'dxDataGrid-groupHeaderText': 'Grupper etter denne kolonnen',
        'dxDataGrid-ungroupHeaderText': 'Fjern gruppe',
        'dxDataGrid-ungroupAllText': 'Fjern alle grupper',
        'dxDataGrid-editingEditRow': 'Rediger',
        'dxDataGrid-editingSaveRowChanges': 'Lagre',
        'dxDataGrid-editingCancelRowChanges': 'Avbryt',
        'dxDataGrid-editingDeleteRow': 'Fjern',
        'dxDataGrid-editingUndeleteRow': 'Gjenopprett',
        'dxDataGrid-editingConfirmDeleteMessage': 'Er du sikker på at du vil slette denne posten?',
        'dxDataGrid-validationCancelChanges': 'Forkast endringer',
        'dxDataGrid-groupPanelEmptyText': 'Dra en kolonne hit for å gruppere',
        'dxDataGrid-noDataText': 'Ingen data',
        'dxDataGrid-searchPanelPlaceholder': 'Søk...',
        'dxDataGrid-filterRowShowAllText': '(Alle)',
        'dxDataGrid-filterRowResetOperationText': 'Tilbakestill',
        'dxDataGrid-filterRowOperationEquals': 'Er lik',
        'dxDataGrid-filterRowOperationNotEquals': 'Er ulik',
        'dxDataGrid-filterRowOperationLess': 'Mindre enn',
        'dxDataGrid-filterRowOperationLessOrEquals': 'Mindre enn eller lik',
        'dxDataGrid-filterRowOperationGreater': 'Større enn',
        'dxDataGrid-filterRowOperationGreaterOrEquals': 'Større enn eller lik',
        'dxDataGrid-filterRowOperationStartsWith': 'Starter med',
        'dxDataGrid-filterRowOperationContains': 'Inneholder',
        'dxDataGrid-filterRowOperationNotContains': 'Inneholder ikke',
        'dxDataGrid-filterRowOperationEndsWith': 'Slutter på',
        'dxDataGrid-filterRowOperationBetween': 'Mellom',
        'dxDataGrid-filterRowOperationBetweenStartText': 'Start',
        'dxDataGrid-filterRowOperationBetweenEndText': 'Slutt',
        'dxDataGrid-applyFilterText': 'Bruk filter',
        'dxDataGrid-trueText': 'sant',
        'dxDataGrid-falseText': 'usant',
        'dxDataGrid-sortingAscendingText': 'Sorter stigende',
        'dxDataGrid-sortingDescendingText': 'Sorter synkende',
        'dxDataGrid-sortingClearText': 'Avbryt sortering',
        'dxDataGrid-editingSaveAllChanges': 'Lagre endringer',
        'dxDataGrid-editingCancelAllChanges': 'Forkast endringer',
        'dxDataGrid-editingAddRow': 'Ny rad',
        'dxDataGrid-summaryMin': 'Min: {0}',
        'dxDataGrid-summaryMinOtherColumn': 'Minimum for {1} er {0}',
        'dxDataGrid-summaryMax': 'Maks: {0}',
        'dxDataGrid-summaryMaxOtherColumn': 'Maksimum for {1} er {0}',
        'dxDataGrid-summaryAvg': 'Ø: {0}',
        'dxDataGrid-summaryAvgOtherColumn': 'Gjennomsnitt for {1} er {0}',
        'dxDataGrid-summarySum': 'Sum: {0}',
        'dxDataGrid-summarySumOtherColumn': 'Sum for {1} er {0}',
        'dxDataGrid-summaryCount': 'Antall: {0}',
        'dxDataGrid-columnFixingFix': 'Fest',
        'dxDataGrid-columnFixingUnfix': 'Løsne',
        'dxDataGrid-columnFixingLeftPosition': 'Til venstre',
        'dxDataGrid-columnFixingRightPosition': 'Til høyre',
        'dxDataGrid-exportTo': 'Eksporter',
        'dxDataGrid-exportToExcel': 'Eksporter til Excel-fil',
        'dxDataGrid-excelFormat': 'Excel-fil',
        'dxDataGrid-selectedRows': 'Valgte rader',
        'dxDataGrid-exportAll': 'Eksporter alle data',
        'dxDataGrid-exportSelectedRows': 'Eksporter valgte rader',
        'dxDataGrid-headerFilterEmptyValue': '(Tomme verdier)',
        'dxDataGrid-headerFilterOK': 'OK',
        'dxDataGrid-headerFilterCancel': 'Avbryt',
        'dxDataGrid-ariaColumn': 'Kolonne',
        'dxDataGrid-ariaValue': 'Verdi',
        'dxDataGrid-ariaFilterCell': 'Filter-celle',
        'dxDataGrid-ariaCollapse': 'Sammenslå',
        'dxDataGrid-ariaExpand': 'Utvid',
        'dxDataGrid-ariaDataGrid': 'Datatabell',
        'dxDataGrid-ariaSearchInGrid': 'Søk i datatabellen',
        'dxDataGrid-ariaSelectAll': 'Velg alle',
        'dxDataGrid-ariaSelectRow': 'Velg rad',
        'dxPager-infoText': 'Side {0} av {1} ({2} elementer)',
        'dxPager-pagesCountText': 'av',
        'dxPivotGrid-grandTotal': 'Totalt',
        'dxPivotGrid-total': '{0} Totalt',
        'dxPivotGrid-fieldChooserTitle': 'Feltvelger',
        'dxPivotGrid-showFieldChooser': 'Vis feltvelger',
        'dxPivotGrid-expandAll': 'Utvid alle',
        'dxPivotGrid-collapseAll': 'Sammenslå alle',
        'dxPivotGrid-sortColumnBySummary': '\'{0}\' sorter etter denne kolonnen',
        'dxPivotGrid-sortRowBySummary': '\'{0}\' sorter etter denne raden',
        'dxPivotGrid-removeAllSorting': 'Fjern sortering',
        'dxPivotGrid-rowFields': 'Radfelter',
        'dxPivotGrid-columnFields': 'Kolonnefelter',
        'dxPivotGrid-dataFields': 'Datafelter',
        'dxPivotGrid-filterFields': 'Filterfelter',
        'dxPivotGrid-allFields': 'Alle felter',
        'dxPivotGrid-columnFieldArea': 'Dra kolonnefelter hit',
        'dxPivotGrid-dataFieldArea': 'Dra datafelter hit',
        'dxPivotGrid-rowFieldArea': 'Dra radfelter hit',
        'dxPivotGrid-filterFieldArea': 'Dra filterfelter hit',
        'dxScheduler-editorLabelTitle': 'Emne',
        'dxScheduler-editorLabelStartDate': 'Starttidspunkt',
        'dxScheduler-editorLabelEndDate': 'Sluttidspunkt',
        'dxScheduler-editorLabelDescription': 'Beskrivelse',
        'dxScheduler-editorLabelRecurrence': 'Repetisjon',
        'dxScheduler-openAppointment': 'Åpen dato',
        'dxScheduler-recurrenceNever': 'Aldri',
        'dxScheduler-recurrenceDaily': 'Daglig',
        'dxScheduler-recurrenceWeekly': 'Ukentlig',
        'dxScheduler-recurrenceMonthly': 'Månedlig',
        'dxScheduler-recurrenceYearly': 'Årlig',
        'dxScheduler-recurrenceEvery': 'Alle',
        'dxScheduler-recurrenceEnd': 'Repetisjonsslutt',
        'dxScheduler-recurrenceAfter': 'Etter',
        'dxScheduler-recurrenceOn': 'På',
        'dxScheduler-recurrenceRepeatDaily': 'Dag(er)',
        'dxScheduler-recurrenceRepeatWeekly': 'Uke(r)',
        'dxScheduler-recurrenceRepeatMonthly': 'Måned(er)',
        'dxScheduler-recurrenceRepeatYearly': 'År',
        'dxScheduler-switcherDay': 'Dag',
        'dxScheduler-switcherWeek': 'Uke',
        'dxScheduler-switcherWorkWeek': 'Arbeidsuke',
        'dxScheduler-switcherMonth': 'Måned',
        'dxScheduler-switcherTimelineDay': 'Tidslinje dag',
        'dxScheduler-switcherTimelineWeek': 'Tidslinje uke',
        'dxScheduler-switcherTimelineWorkWeek': 'Tidslinje arbeidsuke',
        'dxScheduler-switcherTimelineMonth': 'Tidslinje måned',
        'dxScheduler-switcherAgenda': 'Agenda',
        'dxScheduler-recurrenceRepeatOnDate': 'på dato',
        'dxScheduler-recurrenceRepeatCount': 'Hendelser',
        'dxScheduler-allDay': 'Fulltid',
        'dxScheduler-confirmRecurrenceEditMessage': 'Vil du redigere bare denne hendelsen eller hele serien?',
        'dxScheduler-confirmRecurrenceDeleteMessage': 'Vil du slette bare denne hendelsen eller hele serien?',
        'dxScheduler-confirmRecurrenceEditSeries': 'Rediger serien',
        'dxScheduler-confirmRecurrenceDeleteSeries': 'Slett serien',
        'dxScheduler-confirmRecurrenceEditOccurrence': 'Rediger hendelse',
        'dxScheduler-confirmRecurrenceDeleteOccurrence': 'Slett datoer',
        'dxScheduler-noTimezoneTitle': 'Ingen tidssone',
        'dxCalendar-todayButtonText': 'I dag',
        'dxCalendar-ariaWidgetName': 'Kalender',
        'dxColorView-ariaRed': 'Rød',
        'dxColorView-ariaGreen': 'Grønn',
        'dxColorView-ariaBlue': 'Blå',
        'dxColorView-ariaAlpha': 'Gjennomsiktig',
        'dxColorView-ariaHex': 'Fargeverdi',
        'vizExport-printingButtonText': 'Utskrift',
        'vizExport-titleMenuText': 'Eksporter/Skriv ut',
        'vizExport-exportButtonText': '{0}-fil'
    }
};

loadMessages(messagesNo);
locale('nb');
config({ defaultCurrency: 'NOK' });
